import { Controller } from 'stimulus'
import Hls from 'hls.js'

export default class HlsVideoController extends Controller {
  static values = { url: String, retry: Boolean }

  connect() {
    let videoElement = this.element
    if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = this.urlValue
    } else if (Hls.isSupported()) {
      this.hls = new Hls()
      this.hls.loadSource(this.urlValue)
      this.hls.attachMedia(videoElement)
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              if (this.retryValue === true) {
                this.hlsRetryTimer = setTimeout(() => {
                  this.hls.loadSource(this.urlValue)
                  this.hls.startLoad()
                }, 1000)

                this.retries = (this.retries === undefined) ? 1 : (this.retries + 1)
                if (this.retries > 300) { this.retryValue = false }
              }
              break
          }
        }
      })
    }
  }

  disconnect() {
    if (this.hlsRetryTimer) {
      clearTimeout(this.hlsRetryTimer)
    }

    if (this.hls) {
      this.hls.destroy()
    }
  }
}
