import { Controller } from 'stimulus'

// Counts days/hours/minutes/seconds to a particular time specified by
// `data-countdown-time-value`. If `data-countdown-countup-value` is set to `true`,
// will count up. The updated element is `data-countdown-target="container"`.
export default class CountdownController extends Controller {
  static targets = [ 'container' ]
  static values = { time: String, countup: Boolean }

  connect() {
    this.time = new Date(this.timeValue)
    this.interval = setInterval(() => {
      let now = new Date()
      let difference = (this.countupValue === true) ? now - this.time : this.time - now
      let remaining = `${Math.floor((-difference / 1000 / 60) % 60)} minutes ago`
      if (difference > 0) {
        const parts = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        }

        remaining = Object.keys(parts).map(part => {
          if(!(part == 'days' && parts[part] == 0)) {
            return `${parts[part]} ${part}`;
          }
        }).join(" ")
      }

      this.containerTarget.innerHTML = remaining
    }, 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
